import css from './style.module.scss';
import Wrap from '../Wrap';
import Image from 'next/image';
import { gql } from '../../util/fetchGql';
import cls from '../../util/cls';

export const HERO_FRAGMENT = gql`
	fragment Hero on banner_BlockType {
		heading
		text
		image {
			url @transform(
				width: 1720
				height: 560
			)
			contained: url @transform(width: 1720)
			width
			height
		}
	}
`;

export default function Hero ({
	subHeading = null,
	heading,
	text = null,
	image = null,
	black = false,
	containImage = false,
}) {
	return (
		<Wrap El="header" wide className={cls(css.hero, {
			[css.black]: black,
			[css.containImage]: containImage,
		})}>
			<div>
				{subHeading && <em>{subHeading}</em>}
				{heading && <h1>{heading}</h1>}
				{text && <p>{text}</p>}
			</div>
			{image?.length > 0 && (
				<figure>
					<Image unoptimized
						src={containImage ? image[0].contained : image[0].url}
						width={containImage ? 300 : 860}
						height={containImage ? (image[0].height / image[0].width * 300) : 280}
					/>
				</figure>
			)}
		</Wrap>
	);
}
