import Seo from '../components/Seo';
import Hero from '../components/Hero';

export default function Error404 () {
	return (
		<>
			<Seo title="Page not found" />
			<Hero
				heading="Page not found"
				text="We couldn't find the page you were looking for"
			/>
		</>
	);
}
